import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import axios from "axios";
import Swal from "sweetalert2";
import { getSatker } from "../helpers/satker.service";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "hash",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const cekHakAkses = routeTo.matched.some((route) => route.meta.menuIndex);
  const menuLinkList = localStorage.getItem("menuLinkList");

  if (authRequired && !routeTo.path.includes("/login", "/sso-login","/login-dev")) {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      next("/logout-sso");
    } else {
      console.log(
        menuLinkList &&
          cekHakAkses &&
          !JSON.parse(menuLinkList).includes(routeTo.path)
      );
      if (
        menuLinkList &&
        cekHakAkses &&
        !JSON.parse(menuLinkList).includes(routeTo.path)
      ) {
        next("/403");
      }

      try {
        let preloader = document.getElementById("preloader");
        // if (preloader) {
        //   document.getElementById("preloader").style.display = "block";
        //   document.getElementById("status").style.display = "block";
        // }

        // Make a request to your Laravel backend to check the token
        const response = await axios
          .post(
            process.env.VUE_APP_BACKEND_URL_API + "auth/check-token",
            {},
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then((response) => response)
          .catch((err) => {
            console.log(err);
            return false;
          });
        if (response === false) {
          // if (preloader) {
          //   document.getElementById("preloader").style.display = "none";
          //   document.getElementById("status").style.display = "none";
          // }

          localStorage.removeItem("accessToken");
          let timerInterval;
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: "Silahkan login kembali",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              localStorage.clear();
              sessionStorage.clear();
              next("/logout-sso");
            }
          });
        }

        if (!localStorage.getItem("activeSatker") ) {
          await getSatker();
        }
        if (response.data.data) {
          next();
          // if (preloader) {
          //   document.getElementById("preloader").style.display = "none";
          //   document.getElementById("status").style.display = "none";
          // }
        } else {
          if (preloader) {
            // document.getElementById("preloader").style.display = "none";
            // document.getElementById("status").style.display = "none";

            let timerInterval;
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: "Silahkan login kembali",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                localStorage.clear();
                sessionStorage.clear();
                next("/logout-sso");
              }
            });
          }
        }
      } catch (error) {
        next("/logout-sso");
        location.reload();
      }
    }
  } else {
    next();
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  document.title = routeTo.meta.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;
