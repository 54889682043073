<template>
  <div class="d-flex flex-row h-100">
    <iframe
      class="flex-grow-1"
      :src="pdfSrc"
      frameborder="0"
      style="height: 100vh; width: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfSrc:
        process.env.VUE_APP_BACKEND_URL_API +
        `download-sk?file=${this.$route.query.file}`,
    };
  },
};
</script>

<style lang="scss" scoped></style>
