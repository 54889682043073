
import axios from "axios";

export async function getSatker() {
  const currentSatker = localStorage.getItem("activeSatker");
  if (!currentSatker) {
    const user = JSON.parse(localStorage.userData);
    let params = {
      kode_unit_kerja: user.kode_unit_kerja,
      search: user.kode_unit_kerja,
      type: "nonAdmin",
    };
    const data = await axios({
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_API + "reference/satker/all",
      params: params,
      headers: {
        Authorization: localStorage.accessToken,
      },
    });
    console.log(data);

    const listSatker = data.data.data;
    const activeSatker = localStorage.getItem("activeSatker");
    if (listSatker.length && !activeSatker) {
      localStorage.setItem("activeSatker", JSON.stringify(listSatker[0]));
    }
  }
}
