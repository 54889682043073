<template>
  <div class="d-flex flex-row h-100">
    <iframe
      class="flex-grow-1"
      :src="pdfSrc"
      frameborder="0"
      style="height: 100vh; width: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {

  data() {
    return {
      pdfSrc: localStorage.currentDocumentView
    }
  },
  mounted() {
    console.log(this.lineBase);
  },
};
</script>

<style lang="scss" scoped></style>
